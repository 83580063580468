// import node modules
import React, { Component } from "react";
import { Destini } from "@tyson-foods/react-component-library";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

class WhereToBuy extends Component {
  render() {
    return (
      <Layout
        metaTitle="Where to Buy | Galileo® Salame"
        metaDescription={`Find our classic salame and pepperoni products made with quality ingredients and timeless methods in a store near you. Search now!`} 
      >
        <section className="gagl-wtb-wrapper gagl-nav-top-space">
          <header className="gagl-wtb-head-container">
            <h1 className="gagl-wtb-header ">              
              FIND US IN A STORE NEAR YOU!
            </h1>
            <p className="gagl-wtb-para">
              With quality ingredients and timeless methods, our age-old process
              gives our meats an authentic Italian flavor, perfect for any occasion.
            </p>
          </header>
          <div className="gagl-wtb-form-container">
            <Destini siteID="galileo" />
          </div>
        </section>
      </Layout>
    );
  }
}

export default WhereToBuy;
